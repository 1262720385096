import React from "react";
import Logo from "../assets/starlive.png";
const Reconstruction = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-black">
      <div className="mb-8 flex justify-center flex-col">
        <img src={Logo} alt="Logo" className=" h-28 object-contain" />
        <p className="text-lg text-white mb-4 italic text-center">
          You learn more from losing than winning.
        </p>
      </div>

      <p className="text-2xl font-bold text-white text-center">
        {/* მიმდინარეობს საიტის განახლება */}
        Site update in progress
      </p>
    </div>
  );
};

export default Reconstruction;
