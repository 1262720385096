import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/Home";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./pages/About";
import Service from "./pages/Service";
import LoginPage from "./pages/Login";
import RegisterPage from "./pages/Register";
import Reconstruction from "./pages/Reconstruction";

function App() {
  const Layout = ({ children }) => {
    return (
      <div className="w-[100%] flex flex-col justify-center items-center">
        <div className="w-full">
          <Header />
          {children}
          <Footer />
        </div>
      </div>
    );
  };
  const LoginLayout = ({ children }) => {
    return (
      <div className="w-[100%] flex flex-col justify-center items-center">
        <div className="w-full">
          <Header />
          {children}
        </div>
      </div>
    );
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Reconstruction />,
    },
    // {
    //   path: "/about",
    //   element: (
    //     <Layout>
    //       <About />
    //     </Layout>
    //   ),
    // },
    // {
    //   path: "/services",
    //   element: (
    //     <Layout>
    //       <Service />
    //     </Layout>
    //   ),
    // },
    // {
    //   path: "/login",
    //   element: (
    //     <LoginLayout>
    //       <LoginPage />
    //     </LoginLayout>
    //   ),
    // },
    // {
    //   path: "/register",
    //   element: (
    //     <LoginLayout>
    //       <RegisterPage />
    //     </LoginLayout>
    //   ),
    // },
  ]);
  return (
    <div>
      {/* <Header /> */}
      <RouterProvider router={router} />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
